import styled from 'styled-components';
import spacing from '../styles/spacing';

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.l};
  margin-right: ${spacing.l};
`;

export default ResponsiveContainer;
