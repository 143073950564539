import React from 'react';
import styled from 'styled-components';

const Header = (): JSX.Element => (
  <StyledHeader>
    <h1>Hello! : - )</h1>
  </StyledHeader>
);

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Header;
